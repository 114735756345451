import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/menu-section/menu.service';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements OnInit {
  slides = [{ img: 'http://placehold.it/350x150/000000' }, { img: 'http://placehold.it/350x150/111111' }, { img: 'http://placehold.it/350x150/333333' }, { img: 'http://placehold.it/350x150/666666' }];

  newMenu = {};

  constructor(private menuService: MenuService) {
    this.newMenu = menuService.getMenu();
  }

  slideConfig = { slidesToShow: 1, slidesToScroll: 2 };

  addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  ngOnInit() {}
}
