import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// tslint:disable-next-line:no-submodule-imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
// import { LandingComponent } from "./landing/landing.component";
import { ProfileComponent } from './profile/profile.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';
import { EventsComponent } from './events/events.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { NgbdModalContentComponent, NgbdModalComponent } from './modal/modal.component';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { InputsSectionComponent } from './inputs-section/inputs-section.component';
import { CrsSectionComponent } from './crs-section/crs-section.component';
// import { TabsSectionComponent } from "./tabs-section/tabs-section.component";
// import { AngularSectionComponent } from "./angular-section/angular-section.component";
import { NgwWowModule } from 'ngx-wow';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { ExileWineComponent } from './exile-wine/exile-wine.component';
import { OurWinePageComponent } from './our-wine/our-wine-page.component';
import { OurFoodComponent } from './our-food/our-food.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutsRoutingModule } from 'src/app/layout/layouts-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
	provideAnalytics,
	getAnalytics,
	ScreenTrackingService,
	UserTrackingService,
} from '@angular/fire/analytics';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { HttpClientModule } from '@angular/common/http';
// import {HttpClientModule} from '@angular/common';
// import { CafeSectionComponent } from './cafe-section/cafe-section.component';

// import { OurCoffeeComponent } from './our-coffee/our-coffee.component';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>((resolve) => {
	resolvePersistenceEnabled = resolve;
});

@NgModule({
	declarations: [
		AppComponent,
		SignupComponent,
		// LandingComponent,
		ProfileComponent,
		NavbarComponent,
		FooterComponent,
		LoginComponent,
		MenuComponent,
		WhatWeOfferComponent,
		EventsComponent,
		AboutUsComponent,
		InputsSectionComponent,
		CrsSectionComponent,
		// TabsSectionComponent,
		NgbdModalComponent,
		NgbdModalContentComponent,
		GalleryListComponent,
		ExileWineComponent,
		OurWinePageComponent,
		GiftCardComponent,
		OurFoodComponent,
		LayoutComponent,
		// OurWineComponent,
		// OurCoffeeComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HomeModule,
		NgbModule,
		NgwWowModule,
		FormsModule,
		SwiperModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		AppRoutingModule,
		HomeModule,
		FormsModule,
		NgbModule,
		RouterModule,
		JwBootstrapSwitchNg2Module,
		CarouselModule,
		LayoutsRoutingModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAnalytics(() => getAnalytics()),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),
		provideFunctions(() => getFunctions()),
		provideMessaging(() => getMessaging()),
		providePerformance(() => getPerformance()),
		provideRemoteConfig(() => getRemoteConfig()),
		provideStorage(() => getStorage()),
	],
	providers: [ScreenTrackingService, UserTrackingService],
	bootstrap: [AppComponent],
})
export class AppModule {}
