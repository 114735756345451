import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

// import * as $ from "jquery";
import "magnific-popup";

declare const $: any;
@Component({
  selector: "app-cafe-section",
  templateUrl: "./cafe-section.component.html",
  styleUrls: ["./cafe-section.component.scss"],
})
export class CafeSectionComponent implements OnInit {
  @ViewChild("img", { static: false }) imgElement: ElementRef;
  customOptions: OwlOptions = {
    loop: true,
    autoWidth: true,
    mouseDrag: true,
    touchDrag: true,
    autoplay: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }



  slides = [
    { src: "assets/slides/co.jpg", id: 1 },
    { src: "assets/cafe/1.jpeg", id: 2 },
    { src: "assets/cafe/12.jpeg", id: 3 },
    { src: "assets/cafe/2.jpg", id: 4 },
    { src: "assets/cafe/21.jpeg", id: 5 },
    { src: "assets/cafe/21.jpg", id: 6 },
    { src: "assets/cafe/23.jpeg", id: 7 },
    { src: "assets/cafe/32.jpeg", id: 8 },
    { src: "assets/cafe/31.jpg", id: 9 },
    { src: "assets/cafe/33.jpeg", id: 10 },


  ];
  slideConfig = { "slidesToShow": 4, "slidesToScroll": 4 };

  trMenu = [];
  constructor() {
    for (let nm = 1; nm <= 14; nm++) {
      this.trMenu.push(
        {
          id: nm,
          img: `../../assets/menu/tr/${nm}.png`
        }
      )
    }

  }

  ngOnInit() {


  }

  ngAfterViewChecked(): void {

    // Called after ngAfterContentInit when the component's view has been initialized. 
    // Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    const popUp = $(".thumbs .img-pop-up");
    // console.log(popUp);

    if (popUp.length) {
      popUp.magnificPopup({
        tLoading: "Loading image #%curr%...",
        items: [
          { src: "assets/slides/co.jpg", id: 1 },
          { src: "assets/cafe/1.jpeg", id: 2 },
          { src: "assets/cafe/12.jpeg", id: 3 },
          { src: "assets/cafe/2.jpg", id: 4 },
          { src: "assets/cafe/21.jpeg", id: 5 },
          { src: "assets/cafe/21.jpg", id: 6 },
          { src: "assets/cafe/23.jpeg", id: 7 },
          { src: "assets/cafe/32.jpeg", id: 8 },
          { src: "assets/cafe/31.jpg", id: 9 },
          { src: "assets/cafe/33.jpeg", id: 10 }
        ],
        type: "image",

        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1]
        },
        // image: {
        //   tError: "<a href=\"%url%\">The image #%curr%</a> could not be loaded.",
        //   titleSrc(item) {
        //     console.log(item)
        //     return item.el.attr("title") + "<small>by Marsel Van Oosten</small>";
        //   }
        // }

      });
    }

  }
  videoLoaded() {
    console.log("loaded");
  }


  // addSlide() {
  //   this.slides.push({ src: "http://placehold.it/350x150/777777", id: 5 })
  // }

  // removeSlide() {
  //   this.slides.length = this.slides.length - 1;
  // }

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }
}
