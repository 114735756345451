import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	CollectionReference,
	DocumentData,
	addDoc,
	collection,
	deleteDoc,
	doc,
	updateDoc,
	Firestore,
	collectionData,
	docData,
	setDoc,
	query,
	where,
} from '@angular/fire/firestore';
import { traceUntilFirst } from '@angular/fire/performance';
import { persistenceEnabled as _persistenceEnabled } from 'src/app/app.module';
@Injectable({
	providedIn: 'root',
})
export class DataSourceService {
	public readonly persistenceEnabled = _persistenceEnabled;
	constructor(private http: HttpClient, private firestore: Firestore) {}

	Get(source: string, params: any) {
		return this.http.get(source, params);
	}

	Put(source: string, params: any) {
		return this.http.put(source, params);
	}

	Post(source: string, params: any) {
		return this.http.post(source, params);
	}

	Doc$(docRef: string) {
		const ref = doc(this.firestore, docRef);
		return docData(ref).pipe(traceUntilFirst('firestore'));
	}

	Col$(colPath: string) {
		// const ref = collection(this.firestore, colPath);
		const ref = collection(this.firestore, colPath);
		const q = query(ref, where('deleted', '==', false));
		return collectionData(q, { idField: 'id' }).pipe(traceUntilFirst('firestore'));
	}

	CreateDoc(colData: any, col: string, docPath: string) {
		// const ref = doc(this.firestore, col + '/' + docPath);
		const ref = collection(this.firestore, col);
		return addDoc(ref, {
			...colData,
			createdAt: new Date(),
			updatedAt: new Date(),
		});
		// return updateDoc(ref, );
	}

	UpdateDoc(colData: any, col, docPath: string) {
		const ref = this.getDocRef(col, docPath);
		return updateDoc(ref, colData);
	}

	DeleteDoc(col: string, docPath: string) {
		const ref = this.getDocRef(col, docPath);
		return deleteDoc(ref);
	}

	private getDocRef(col: any, docPath: string) {
		return doc(this.firestore, col + '/' + docPath);
	}
}
