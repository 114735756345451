import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
// import { SignupComponent } from "./signup/signup.component";
// import { LandingComponent } from "./landing/landing.component";
// import { LoginComponent } from "./login/login.component";
import { MenuComponent } from './menu/menu.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';
import { AboutUsComponent } from './about-us/about-us.component';
// import { GiftCardComponent } from "./gift-card/gift-card.component";
import { EventsComponent } from './events/events.component';
// import { OurWineComponent } from "./our-wine-section/our-wine.component";
import { ExileWineComponent } from './exile-wine/exile-wine.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuardGuard } from './auth-guard.guard';
const routerOptions: ExtraOptions = {
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'enabled',
	scrollOffset: [0, 64],
	useHash: false,
	relativeLinkResolution: 'legacy',
};

const routes: Routes = [
	{
		path: 'cafe',
		loadChildren: () => import('./layout/layouts.module').then((m) => m.LayoutsModule),
	},
	{
		path: 'admin',
		component: AdminComponent,
		loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
		canActivate: [AuthGuardGuard],
	},
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, routerOptions), //
	],
	exports: [],
})
export class AppRoutingModule {}
