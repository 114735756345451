import { Injectable, INJECTOR } from '@angular/core';
import { Router } from '@angular/router';
import {
	Auth,
	authState,
	signInAnonymously,
	signOut,
	User,
	GoogleAuthProvider,
	signInWithPopup,
	signInWithEmailAndPassword,
} from '@angular/fire/auth';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { traceUntilFirst } from '@angular/fire/performance';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private auth: Auth, private router: Router) {}

	async isLoggedIn(): Promise<boolean> {
		return await new Promise((resolve, reject) => {
			authState(this.auth).subscribe((user) => {
				console.log(user);
				resolve(user ? true : false);
			});
		}); //. ? true : false;
	}

	signIn(values: { email: string; password: string }) {
		return signInWithEmailAndPassword(this.auth, values.email, values.password);
	}

	logOut() {
		this.auth.signOut().then(() => {
			this.router.navigate(['/home']);
		});
	}
}
