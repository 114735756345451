import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataSourceService } from '../admin/shared/services/data-source.service';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(private api: DataSourceService, public auth: AuthService, private router: Router) {}

	login(params) {
		return this.auth
			.signIn(params)
			.then((response) => {
				this.router.navigate(['/admin']);
			})
			.catch((e) => {
				console.log(e);
				return e;
			});
	}
}
