import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";

import { HomeComponent } from "./home.component";
import { MenuListComponent } from "../menu-section/menu-list.component";
import { AboutSectionComponent } from "../about-section/about-section.component";
import { ContactSectionComponent } from "../contact-section/contact-section.component";
import { ScrollSpyDirective } from "../directives/scrol-spy";
import { OurCoffeeComponent } from "../our-coffee-section/our-coffee.component";
import { CafeSectionComponent } from "../cafe-section/cafe-section.component";
import { OurWineComponent } from "../our-wine-section/our-wine.component";
import { CarouselModule } from "ngx-owl-carousel-o";

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		NgbCarouselModule,

		RouterModule,
		CarouselModule
	],
	declarations: [
		HomeComponent,
		MenuListComponent,
		AboutSectionComponent,
		CafeSectionComponent,
		OurCoffeeComponent,
		OurWineComponent,
		ContactSectionComponent,
		ScrollSpyDirective
	],
	exports: [HomeComponent],
	providers: []
})
export class HomeModule {}
