import {
	Component,
	OnInit,
	Inject,
	ElementRef,
	ViewChild,
	HostListener,
	Renderer2,
	AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import {
	LocationStrategy,
	PlatformLocation,
	Location,
	DOCUMENT
} from '@angular/common';
import { NgwWowService } from 'ngx-wow';

// tslint:disable-next-line: no-var-keyword
let lastScrollTop = 0;
const delta = 5;
const navbarHeight = 0;
// tslint:disable-next-line: no-var-keyword

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
	showLoading = true;
	// tslint:disable-next-line:variable-name
	darkLogo = './assets/exile-2.png';
	whiteLogo = './assets/Exilewhite.png';
	logo = this.darkLogo;
	constructor(
		private renderer: Renderer2,
		private router: Router,
		@Inject(DOCUMENT) private document: any,
		private element: ElementRef,
		public location: Location,
		private wowService: NgwWowService
	) {}

	ngOnInit() {}

	ngAfterViewInit() {
		setTimeout(() => {
			this.showLoading = false;
		}, 1000);
	}
}
