import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-coffee',
  templateUrl: './our-coffee.component.html',
  styleUrls: ['./our-coffee.component.scss']
})
export class OurCoffeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
