import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	focus;
	focus1;
	loginForm: FormGroup;
	constructor(private formBuilder: FormBuilder, private loginService: LoginService) {}

	ngOnInit() {
		console.log('');
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]],
		});
	}

	submitForm() {
		if (this.loginForm.invalid) {
			return;
		}

		this.loginService.login(this.loginForm.value).catch((e) => {
			console.log(e);
		});
	}
}
