import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	ChangeDetectorRef
} from "@angular/core";
import { PaymentService } from "../services/payment/payment.service";
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators
} from "@angular/forms";
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";
import { Observable, of } from "rxjs";

export interface CHARGEDATA {
	amount: number;
	quantity: number;
	email: string;
	name: string;
	// token: string;
	source: any;
	productId: string;
}
@Component({
	selector: "app-gift-card",
	templateUrl: "./gift-card.component.html",
	styleUrls: ["./gift-card.component.scss"]
})
export class GiftCardComponent implements OnInit {
	@Input() amount: number;
	@Input() description: string;
	@ViewChild("cardElement", { static: false }) cardElement: ElementRef;

	exileEmail = "exilegiftcard@exilecoffee.com";
	cards: Observable<any>;
	stripe; // : stripe.Stripe;
	card;
	cardErrors;

	loading = false;
	confirmation;
	intent;
	steps: any;
	quantity: any;
	name: string;
	email: string;

	infoForm: UntypedFormGroup;
	productQuantity: Array<number>;
	giftChoice: any;
	showProgress = true;
	showComplete = false;
	products = [
		{
			id: 1,
			price: 25
		},
		{
			id: 2,
			price: 50
		},
		{
			id: 3,
			price: 100
		}
	];
	btnDisabled: boolean;
	dForm: boolean;
	codes: Observable<any>;

	constructor(
		public changeRef: ChangeDetectorRef,
		private paymentService: PaymentService,
		private formBuilder: UntypedFormBuilder
	) {
		this.steps = 1;
		this.productQuantity = [];
		for (let i = 1; i <= 20; i++) {
			this.productQuantity.push(i);
		}
	}
	handler: any = null;

	ngOnInit() {
		this.createForm();
	}

	loadStripe() {
		this.stripe = Stripe("pk_test_2KO59vSdWG11nfZryPhs8EpF008281b2Pr");
		const elements = this.stripe.elements();

		this.card = elements.create("card");
		this.card.mount(this.cardElement.nativeElement);

		this.card.addEventListener("change", ({ error }) => {
			this.cardErrors = error && error.message;
			console.log(error);
		});
	}

	createForm() {
		this.infoForm = this.formBuilder.group({
			quantity: [1, Validators.compose([Validators.required])],
			name: ["", Validators.compose([Validators.required])],
			email: ["", Validators.compose([Validators.required, Validators.email])]
		});
	}

	get customerName() {
		return this.infoForm.get("name").value;
	}

	get customerEmail() {
		return this.infoForm.get("email").value;
	}

	get giftQuantity() {
		return this.infoForm.get("quantity").value;
	}

	nextStep(step: number, choice: any) {
		if (this.dForm) {
			return;
		}
		console.log(choice);
		this.amount = choice.price;
		this.steps = step;
		console.log(step);
		this.giftChoice = choice;
		this.changeRef.detectChanges();
		if (step === 2) {
			// keep valuse
		}
		if (step === 3) {
			// this.
			console.log(this.infoForm.value);
			this.loadStripe();
			// if (!this.card) {
			// }
		}
	}
	async handleForm(e) {
		e.preventDefault();
		this.btnDisabled = true;

		const { source, error } = await this.stripe.createSource(this.card);

		if (error) {
			// Inform the customer that there was an error.
			this.cardErrors = error.message;
			this.btnDisabled = false;
		} else {
			this.dForm = true;
			this.loading = true;
			this.showProgress = false;
			const data: CHARGEDATA = {
				amount: this.amount,
				email: "derekjamabo@gmail.com",
				name: "Derek Jamabo",
				productId: "13434as",
				quantity: this.giftQuantity,
				source: source.source
				// token:
			};
			// Send the token to your server.

			// const user = await this.auth.getUser();
			// const fun = this.functions.httpsCallable('stripeCreateCharge');
			const intent = this.paymentService.createIntent(data).toPromise();

			intent
				.then((res: any) => {
					console.log(res);
					this.confirmCardPayment(res.intent, res.id);
				})
				.catch(error => {
					this.cardErrors = error;
					this.loading = false;
					this.showProgress = false;
					this.btnDisabled = false;
					this.nextStep(3, this.giftChoice);
				});
			console.log(this.intent);

			// console.log(this.confirmation);
		}
	}

	private async confirmCardPayment(intent: any, id: string) {
		try {
			await this.stripe
				.confirmCardPayment(intent.client_secret, {
					payment_method: {
						card: this.card
					}
				})
				.then(async (res: any) => {
					// console.log(res);
					if (res.paymentIntent) {
						const updateIntent = this.paymentService.updateIntent(
							id,
							this.customerEmail,
							res.paymentIntent,
							this.giftQuantity,
							this.customerName
						);
						updateIntent.subscribe(
							(resData: any) => {
								if (resData.status === true) {
									this.codes = of(resData.codes);
								}
								this.loading = false;
								this.showComplete = true;
							},
							error => {
								console.log(error);
								if (error.status === 404) {
									this.cardErrors = `Opps an error occurred while connecting to server, please try again later`;
								} else {
									this.cardErrors = error.message;
								}
								this.loading = false;
								this.showProgress = true;
								this.btnDisabled = false;
							}
						);
					} else if (res.error) {
						console.log(res.error.message);
						this.cardErrors = res.error.message;
						this.loading = false;
						this.showProgress = true;
						this.btnDisabled = false;
					}
				})
				.catch(e => {
					console.log(e);
					this.loading = false;
					this.showProgress = true;
					this.btnDisabled = false;
					this.cardErrors = "An error occoured";
				});
		} catch (error) {
			console.log(error);
			this.loading = false;
			this.showProgress = true;
			this.btnDisabled = false;
			this.cardErrors = "An error occoured";
		}
	}

	prevStep(step: number) {
		if (this.steps === step) return;

		if (step > this.steps) return;

		this.steps = step;
	}

	setQuantity(event: any) {
		this.quantity = event.target.value;
	}

	public convetToPDF() {
		let data = document.getElementById("contentToConvert");
		html2canvas(data).then(canvas => {
			// Few necessary setting options
			let imgWidth = 208;
			let pageHeight = 295;
			let imgHeight = (canvas.height * imgWidth) / canvas.width;
			let heightLeft = imgHeight;

			const contentDataURL = canvas.toDataURL("image/png");
			// @ts-ignore
			let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
			let position = 0;
			pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
			pdf.save("new-file.pdf"); // Generated PDF
		});
	}
}
