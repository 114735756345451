import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { HomeComponent } from 'src/app/home/home.component';
import { MenuComponent } from 'src/app/menu/menu.component';
import { WhatWeOfferComponent } from 'src/app/what-we-offer/what-we-offer.component';
import { AboutUsComponent } from 'src/app/about-us/about-us.component';
import { EventsComponent } from 'src/app/events/events.component';
import { ExileWineComponent } from 'src/app/exile-wine/exile-wine.component';
import { LoginComponent } from 'src/app/login/login.component';
import { ProfileComponent } from 'src/app/profile/profile.component';
const routerOptions: ExtraOptions = {
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'enabled',
	scrollOffset: [0, 64],
	useHash: true,
	relativeLinkResolution: 'legacy',
};
const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		children: [
			{ path: 'home', component: HomeComponent },
			{ path: 'menu', component: MenuComponent },
			{ path: 'what-we-offer', component: WhatWeOfferComponent },
			// { path: "gift-card", component: GiftCardComponent },
			{ path: 'about-us', component: AboutUsComponent },
			{ path: 'events/:id', component: EventsComponent },
			{ path: 'exile-wine', component: ExileWineComponent },
			// { path: "landing", component: LandingComponent },
			{ path: 'login', component: LoginComponent },
			{ path: 'profile', component: ProfileComponent },
			{ path: '', redirectTo: 'home', pathMatch: 'full' },
			{ path: '**', redirectTo: 'home', pathMatch: 'full' },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class LayoutsRoutingModule {}
