import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	CollectionReference,
	DocumentData,
	addDoc,
	collection,
	deleteDoc,
	doc,
	updateDoc,
	Firestore,
	collectionData,
	docData,
	setDoc,
	query,
	where,
} from '@angular/fire/firestore';
import { traceUntilFirst } from '@angular/fire/performance';
import { persistenceEnabled as _persistenceEnabled } from 'src/app/app.module';
@Injectable({
	providedIn: 'root',
})
export class DataSourceService {
	public readonly persistenceEnabled = _persistenceEnabled;
	constructor(private http: HttpClient, private firestore: Firestore) {}

	Get(source: string, params: any) {
		return this.http.get(source, params);
	}

	Post(source: string, params: any) {
		return this.http.post(source, params);
	}

	Doc$(docRef: string) {
		const ref = doc(this.firestore, docRef);
		return docData(ref).pipe(traceUntilFirst('firestore'));
	}

	Col$(colPath: string) {
		const ref = collection(this.firestore, colPath);
		const q = query(ref, where('isPublished', '==', true));
		return collectionData(q, { idField: 'id' }).pipe(traceUntilFirst('firestore'));
	}

	private getDocRef(col: any, docPath: string) {
		return doc(this.firestore, col + '/' + docPath);
	}
}
