import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { authState } from '@angular/fire/auth';
import { AuthService } from './services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
	constructor(private authService: AuthService) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const isLoggedIn = this.authService.isLoggedIn();
		return isLoggedIn;
	}
}
